import { internalHttpClient, API_SERVICE_URLS } from '@flowardco/flib-helpers';
import type { HelpcenterOrderOpsModel } from '@flowardco/flib-models';

export const orderClient = internalHttpClient({
  baseURL: API_SERVICE_URLS.ORDER,
});
export const orderV2Client = internalHttpClient({
  baseURL: API_SERVICE_URLS.ORDERV2,
});
export const client = internalHttpClient({ baseURL: API_SERVICE_URLS.API2 });
export const kongHomeClient = internalHttpClient({
  baseURL: API_SERVICE_URLS.KONG_HOME,
});

export const addRequestHeaders = ({
  operation,
  userToken,
  lang,
}: {
  operation: HelpcenterOrderOpsModel;
  userToken: string;
  lang: string;
}) => {
  const opsCenterId = operation?.id || '';
  const opsCountryId = operation?.countryId || '';
  const language = lang === 'ar' ? 0 : 1;

  const headers = {
    lang: language,
    opsId: opsCenterId,
    opsCountryId: opsCountryId,
    Authorization: `Bearer ${userToken}`,
  };

  return headers;
};
