import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';
import {
  API_ENDPOINTS,
  formatUrlParams,
  formatHttpResponse,
} from '@flowardco/flib-helpers';

import {
  addRequestHeaders,
  client,
  kongHomeClient,
  orderClient,
} from './api.service';
import type { LocalAppContextProps } from '@/Context/LocalAppContext';
import type { GroupedListType } from '@flowardco/fui-search-select';
import type {
  AreaAPIModel,
  GeoFencedAreaAPIModel,
  SubAreaAPIModel,
  SubGeoFencedAreaAPIModel,
} from '@flowardco/flib-models';
import type { SelectListType } from '@flowardco/fui-input-field';

export const getSavedAddressList = async (appState: LocalAppContextProps) => {
  try {
    const { orderData, userToken, lang } = appState;
    const headers = addRequestHeaders({
      operation: orderData?.operation!,
      userToken,
      lang,
    });
    const res = await client.get(
      formatUrlParams(API_ENDPOINTS.user.helpCenterApp.getSavedAddress, {
        userId: orderData?.order?.userId,
        opsId: orderData?.operation?.id,
      }),
      {
        headers,
      }
    );
    const orderDetailsData = formatHttpResponse(res);
    return orderDetailsData;
  } catch (error: any) {
    errorLoggerV2('AddressService::getSavedAddressList', error);
    return [];
  }
};

export const getCountryList = async () => {
  try {
    const data = await kongHomeClient.get(API_ENDPOINTS.home.countryList);
    const countryList = formatHttpResponse(data);
    return countryList;
  } catch (error: any) {
    errorLoggerV2('AddressService::getCountryList', error);
    return [];
  }
};

export const getLeaveLocations = async (appState: LocalAppContextProps) => {
  try {
    const { orderData, userToken, lang } = appState;
    const headers = addRequestHeaders({
      operation: orderData?.operation!,
      userToken,
      lang,
    });
    const data = await orderClient.get(API_ENDPOINTS.leaveLocations, {
      headers,
    });
    const formattedResponse = formatHttpResponse(data);
    if (formattedResponse && formattedResponse?.length > 0) {
      const newLeaveLocations: { value: number; label: string }[] =
        formattedResponse?.map((ll: { id: number; title: string }) => ({
          value: ll.id,
          label: ll.title,
        }));
      return newLeaveLocations;
    }
    return formattedResponse || [];
  } catch (error: any) {
    errorLoggerV2('AddressService::getLeaveLocations', error);
    return [];
  }
};

export const saveRecipientAddress = async ({
  appState,
  data,
}: {
  appState: LocalAppContextProps;
  data: any;
}) => {
  try {
    const { orderData, userToken, orderToken, lang } = appState;
    const headers = addRequestHeaders({
      operation: orderData?.operation!,
      userToken,
      lang,
    });
    const res = await client.post(
      formatUrlParams(API_ENDPOINTS.helpCenterApp.saveDeliveryAddress, {
        orderToken,
      }),
      data,
      {
        headers,
      }
    );
    const response = formatHttpResponse(res);
    return response;
  } catch (error: any) {
    throw Error(error?.response?.data?.message);
  }
};

export const getAreaListForOps = async ({
  opsId,
  __TK,
  mapMaster = false,
}: {
  opsId: number;
  __TK: (en?: string, ar?: string) => string;
  mapMaster?: boolean;
}) => {
  try {
    const areaListInfo = mapMaster
      ? await getGeofencedAreaList({ opsId, __TK })
      : await getAreaList({ opsId, __TK });
    return areaListInfo;
  } catch (error: any) {
    errorLoggerV2('AddressService::getAreaListForOps', error);
    return { areaNamesList: [], areaNamesFlatList: [] };
  }
};

export const getGeofencedAreaList = async ({
  opsId,
  __TK,
}: {
  opsId: number;
  __TK: (en?: string, ar?: string) => string;
}) => {
  try {
    const res = await kongHomeClient.get(
      API_ENDPOINTS.home.getGeofencedAreaList,
      {
        headers: {
          opsId,
        },
      }
    );
    const areaListInfo = formatHttpResponse(res);
    const areaNamesFlatList: SelectListType[] =
      areaListInfo
        ?.flatMap((itm: GeoFencedAreaAPIModel) => itm.subarea)
        ?.map((subArea: SubGeoFencedAreaAPIModel) => ({
          label: __TK(subArea?.name, subArea?.nameAR),
          value: subArea?.id,
        })) || [];
    const areaNamesList: GroupedListType[] = areaListInfo?.reduce(
      (acc: any, itm: GeoFencedAreaAPIModel) => {
        const label = __TK(itm?.name, itm?.nameAR);
        const options =
          itm?.subarea?.map((subArea: SubGeoFencedAreaAPIModel) => ({
            label: __TK(subArea?.name, subArea?.nameAR),
            value: subArea?.id,
          })) || [];
        return acc.concat({ label, options });
      },
      []
    );
    return { areaNamesList, areaNamesFlatList };
  } catch (error: any) {
    errorLoggerV2('AddressPage::getGeofencedAreaList', error);
    return { areaNamesList: [], areaNamesFlatList: [] };
  }
};
export const getAreaList = async ({
  opsId,
  __TK,
}: {
  opsId: number;
  __TK: (en?: string, ar?: string) => string;
}) => {
  try {
    const res = await kongHomeClient.get(API_ENDPOINTS.home.getAreaList, {
      headers: {
        opsId,
      },
    });
    const areaListInfo = formatHttpResponse(res);
    const areaNamesFlatList: SelectListType[] =
      areaListInfo
        ?.flatMap((itm: AreaAPIModel) => itm.subarea)
        ?.map((subArea: SubAreaAPIModel) => ({
          label: __TK(subArea?.Name, subArea?.NameAR),
          value: subArea?.Id,
        })) || [];
    const areaNamesList: GroupedListType[] = areaListInfo?.reduce(
      (acc: any, itm: AreaAPIModel) => {
        const label = __TK(itm?.Name, itm?.NameAR);
        const options =
          itm?.subarea?.map((subArea: SubAreaAPIModel) => ({
            label: __TK(subArea?.Name, subArea?.NameAR),
            value: subArea?.Id,
          })) || [];
        return acc.concat({ label, options });
      },
      []
    );
    return { areaNamesList, areaNamesFlatList };
  } catch (error: any) {
    errorLoggerV2('AddressPage::getAreaList', error);
    return { areaNamesList: [], areaNamesFlatList: [] };
  }
};
