import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';

import {
  API_ENDPOINTS,
  formatUrlParams,
  formatHttpResponse,
} from '@flowardco/flib-helpers';

import { addRequestHeaders, client, kongHomeClient } from './api.service';
import type { LocalAppContextProps } from '@/Context/LocalAppContext';
import { addToast } from '@flowardco/flib-util';

export const getOrderDetails = async (appState: LocalAppContextProps) => {
  try {
    const { orderData, userToken, lang } = appState;
    const headers = addRequestHeaders({
      operation: orderData?.operation!,
      userToken,
      lang,
    });
    const res = await client.get(
      formatUrlParams(API_ENDPOINTS.helpCenterApp.getOrderDetails, {
        orderToken: appState?.orderToken,
      }),
      {
        headers,
      }
    );
    const orderDetailsData = formatHttpResponse(res);
    return orderDetailsData;
  } catch (error: any) {
    errorLoggerV2('OrderService::getOrderDetails', error);
    return [];
  }
};
export const getCustomerCareData = async (appState: LocalAppContextProps) => {
  try {
    const { orderData, userToken, lang } = appState;
    const headers = addRequestHeaders({
      operation: orderData?.operation!,
      userToken,
      lang,
    });
    const res = await kongHomeClient.get(
      formatUrlParams(API_ENDPOINTS.home.helpCenterApp.customerCare, {
        orderToken: appState?.orderToken,
      }),
      {
        headers,
      }
    );
    const customerCareData = formatHttpResponse(res);
    return customerCareData;
  } catch (error: any) {
    errorLoggerV2('OrderService::getCustomCareData', error);
    return [];
  }
};

export const cancelOrder = async (
  values: any,
  appState: LocalAppContextProps,
  __T: any
) => {
  try {
    const { orderData, userToken, lang } = appState;
    const headers = addRequestHeaders({
      operation: orderData?.operation!,
      userToken,
      lang,
    });
    const res = await client.post(
      formatUrlParams(API_ENDPOINTS.helpCenterApp.cancelOrder, {
        orderToken: appState?.orderToken,
      }),
      values,
      {
        headers,
      }
    );
    const formattedResponse = formatHttpResponse(res);
    if (formattedResponse?.success) {
      addToast(
        __T(
          'We received your cancellation request, and your order will no longer be processed.You will receive a refund in the form of Floward credit. Credit will show in your wallet within 72 hours.'
        )
      );
    }
    return formattedResponse;
  } catch (error: any) {
    addToast(error?.response?.data?.message, 'error');
    errorLoggerV2('OrderService::cancelOrder', error);
    return [];
  }
};
